.loader {
  
  &__wrapper {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    overflow: hidden;
    height: calc(100vh - 6rem);
    width: calc(100vw - 6rem);
    @include respond(tab-port){
      height: 100vh;
      width: 100vw; 
    }

    background-color: $color-secondary-dark;
  }
  &__text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-primary;
  }
}

.pulse {
  background-color: $color-secondary-dark;
  height: 100px;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.pulse:after {
  content: "";
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200px 100px" enable-background="new 0 0 200px 100px" xml:space="preserve"><polyline fill="none" stroke-width="3px" stroke="'+$color-primary-light+'" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>')
    0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 2s pulse linear infinite;
  -moz-animation: 2s pulse linear infinite;
  -o-animation: 2s pulse linear infinite;
  animation: 2s pulse linear infinite;
  clip: rect(0, 0, 100px, 0);
}
.pulse:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 3px;
}

@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
    opacity: 0.4;
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
    opacity: 0.6;
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
    opacity: 0.8;
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }

  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }

  90% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }

  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }

  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}
