/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 64em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  padding: 3rem;
}
@media only screen and (max-width: 64em) {
  body {
    padding: 0;
  }
}

::selection {
  background-color: #333333;
  color: #fff;
}

hr {
  border-color: #e2c174;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #333;
  font-size: 1.6rem;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.heading-secondary {
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
@supports (-webkit-background-clip: text) {
  .heading-secondary {
    -webkit-background-clip: text;
    background-image: linear-gradient(to right, #e2c174, #deb85f, #444444);
    color: transparent;
  }
}
@media only screen and (max-width: 64em) {
  .heading-secondary {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}

.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  font-size: 1.6rem;
}
.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 6rem !important;
}
@media only screen and (max-width: 64em) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-huge {
  margin-bottom: 16rem !important;
}

.u-margin-bottom-large {
  margin-bottom: 6rem !important;
}
@media only screen and (max-width: 64em) {
  .u-margin-bottom-large {
    margin-bottom: 8rem !important;
  }
}

.u-padding-bottom-small {
  padding-bottom: 4rem !important;
}

.u-padding-bottom-medium {
  padding-bottom: 6rem !important;
}

.u-padding-bottom-large {
  padding-bottom: 8rem !important;
}

.u-padding-bottom-huge {
  padding-bottom: 16rem !important;
}

.u-margin-top-tiny {
  margin-top: 1rem !important;
}

.u-margin-top-small {
  margin-top: 4rem !important;
}

.u-margin-top-medium {
  margin-top: 6rem !important;
}
@media only screen and (max-width: 64em) {
  .u-margin-top-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-top-large {
  margin-top: 8rem !important;
}
@media only screen and (max-width: 64em) {
  .u-margin-top-large {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-huge {
  margin-top: 16rem !important;
}

.u-padding-top-small {
  padding-top: 4rem !important;
}

.u-padding-top-medium {
  padding-top: 6rem !important;
}

.u-padding-top-large {
  padding-top: 8rem !important;
}

.u-padding-top-huge {
  padding-top: 24rem !important;
}

.u-box-shadow {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}
.row:not(:last-child) {
  margin-bottom: 6rem;
}
@media only screen and (max-width: 64em) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 64em) {
  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^=col-] {
  float: left;
}
.row [class^=col-]:not(:last-child) {
  margin-right: 4rem;
}
@media only screen and (max-width: 64em) {
  .row [class^=col-]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 64em) {
  .row [class^=col-] {
    width: 100% !important;
  }
}
.row .col-1-of-2 {
  width: calc((100% - 4rem - .5rem) / 2);
}
.row .col-1-of-3 {
  width: calc((100% - 2 * 4rem - .5rem) / 3);
}
.row .col-2-of-3 {
  width: calc(
        2 * ((100% - 2 * 4rem - .5rem) / 3) + 4rem
      );
}
.row .col-1-of-4 {
  width: calc((100% - 3 * 4rem - .5rem) / 4);
}
.row .col-2-of-4 {
  width: calc(
        2 * ((100% - 3 * 4rem - .5rem) / 4) + 4rem
      );
}
.row .col-3-of-4 {
  width: calc(
        3 * ((100% - 3 * 4rem - .5rem) / 4) + 2 * 4rem
      );
}

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
}
.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.btn:active, .btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.btn--white {
  background-color: #fff;
  color: #333;
}
.btn--white::after {
  background-color: #fff;
}
.btn--primary {
  background-color: #deb85f;
  color: #fff;
}
.btn--primary::after {
  background-color: #deb85f;
}
.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.btn--animated {
  opacity: 0;
  backface-visibility: hidden;
  animation: moveInBottom 0.5s ease-out 1.75s forwards;
}

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #deb85f;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #deb85f;
  padding: 3px;
  transition: all 0.2s;
}
.btn-text:hover {
  background-color: #deb85f;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}
.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0);
}

.loader__wrapper {
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100vh - 6rem);
  width: calc(100vw - 6rem);
  background-color: #222222;
}
@media only screen and (max-width: 64em) {
  .loader__wrapper {
    height: 100vh;
    width: 100vw;
  }
}
.loader__text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #deb85f;
}

.pulse {
  background-color: #222222;
  height: 100px;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.pulse:after {
  content: "";
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200px 100px" enable-background="new 0 0 200px 100px" xml:space="preserve"><polyline fill="none" stroke-width="3px" stroke="#e2c174" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>') 0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 2s pulse linear infinite;
  -moz-animation: 2s pulse linear infinite;
  -o-animation: 2s pulse linear infinite;
  animation: 2s pulse linear infinite;
  clip: rect(0, 0, 100px, 0);
}

.pulse:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 3px;
}

@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
    opacity: 0.4;
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
    opacity: 0.6;
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
    opacity: 0.8;
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }
  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  90% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }
  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}