// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// COLORS
$color-primary: #deb85f;
$color-primary-light: #e2c174;
$color-primary-dark: #daaf4a;
$color-secondary: #333333;
$color-secondary-light: #444444;
$color-secondary-dark: #222222;
// $color-tertiary-light: #2998ff;
// $color-tertiary-dark: #5643fa;
$color-text: #222222;
$color-gray: #eee;
$color-gray-light: #f7f7f7;
$color-gray-mid: #999;
$color-gray-dark: #333;
$color-white: #fff;
$color-black: #000;
$color-test-1: #e4e4e4;
$color-test-2: #dfcc9d;
// FONT
$default-font-size: 1.6rem;
// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 4rem;