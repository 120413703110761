.u-center-text {
    text-align: center !important;
}

// BOTTOM
.u-margin-bottom-small {
    margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
    margin-bottom: 6rem !important;
    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-bottom-huge {
    margin-bottom: 16rem !important;
}

.u-margin-bottom-large {
    margin-bottom: 6rem !important;
    @include respond(tab-port) {
        margin-bottom: 8rem !important;
    }
}

.u-padding-bottom-small {
    padding-bottom: 4rem !important;
}

.u-padding-bottom-medium {
    padding-bottom: 6rem !important;
}

.u-padding-bottom-large {
    padding-bottom: 8rem !important;
}

.u-padding-bottom-huge {
    padding-bottom: 16rem !important;
}

// TOP
.u-margin-top-tiny {
    margin-top: 1rem !important;
}

.u-margin-top-small {
    margin-top: 4rem !important;
}

.u-margin-top-medium {
    margin-top: 6rem !important;
    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.u-margin-top-large {
    margin-top: 8rem !important;
    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.u-margin-top-huge {
    margin-top: 16rem !important;
}

.u-padding-top-small {
    padding-top: 4rem !important;
}

.u-padding-top-medium {
    padding-top: 6rem !important;
}

.u-padding-top-large {
    padding-top: 8rem !important;
}

.u-padding-top-huge {
    padding-top: 24rem !important;
}

.u-box-shadow {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}