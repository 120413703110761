@import "../abstracts/variables";
body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: $color-gray-dark;
    font-size: $default-font-size;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.heading-secondary {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    @supports (-webkit-background-clip: text) {
        -webkit-background-clip: text;
        background-image: linear-gradient( to right, $color-primary-light, $color-primary, $color-secondary-light);
        color: transparent;
    }
    letter-spacing: 0.2rem;
    transition: all 0.2s;
    @include respond(tab-port) {
        font-size: 3rem;
    }
    @include respond(phone) {
        font-size: 2.5rem;
    }
    // &:hover {
    // transform: skewY(2deg) skewX(15deg) scale(1.1);
    // text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
    // }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}